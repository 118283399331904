<template>
    <div class="min-h-screen flex justify-center bg-gray-50 py-12 px-4 sm:px-6 lg:px-8">
        <div class="max-w-md w-full space-y-8">
            <div>
                <h2 class="mt-6 text-center text-3xl font-extrabold text-gray-900">
                    검색을 위한 정보를 입력하세요.
                </h2>
            </div>
            <form class="mt-8 space-y-6" @submit.prevent="submit">
                <div class="rounded-md shadow-sm -space-y-px">
                    <div>
                        <label for="roomName" class="sr-only">Room Name</label>
                        <input id="roomName" name="roomName" type="text" v-model="roomName" required class="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-t-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm" placeholder="방 이름">
                    </div>
                    <div>
                        <label for="userId" class="sr-only">User ID</label>
                        <input id="userId" name="userId" type="text" v-model="userId" required class="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-t-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm" placeholder="유저 이름">
                    </div>
                </div>

                <div>
                    <button type="submit" class="group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
                        검색
                    </button>
                </div>
            </form>
            <div class="mt-8">
                <h3 class="text-lg leading-6 font-medium text-gray-900">파일 목록</h3>
                <ul v-if="fileList.length > 0" class="mt-2 border-t border-b border-gray-200 divide-y divide-gray-200">
                    <li v-for="(file, index) in fileList" :key="index" class="py-3 flex justify-between items-center">
                        <p class="text-sm font-medium text-gray-900">{{ file }}</p>
                        <a :href="`${baseUrl}/waves/${roomName}/${file}`" download class="text-sm font-medium text-indigo-600 hover:text-indigo-500">다운로드</a>
                    </li>
                </ul>
                <p v-else class="mt-2 text-sm text-gray-500 text-center">검색된 파일이 없습니다.</p>
            </div>
        </div>
    </div>
</template>

<script>
import axios from "axios";

export default {
    name: 'Download',
    data: function () {
        return {
            roomName: '',
            userId: '',
            baseUrl: this.$debug ? 'http://localhost:8080' : '',
            fileList: [],
        }
    },
    methods: {
        submit: function () {
            // validation
            if (this.roomName === '' || this.userId === '') {
                alert('방 이름과 유저 이름을 입력해주세요.');
                return;
            }
            // get file list
            axios.get(this.baseUrl + '/api/manager/wave-list', {
                params: {
                    roomName: this.roomName,
                    userId: this.userId
                }
            }).then((res) => {
                this.fileList = res.data;
            }).catch((error) => {
                if (error.response && error.response.status === 404) {
                    alert(error.response.data);
                } else if (error.response && error.response.status === 401) {
                    alert('로그인이 필요합니다.');
                    this.$router.push('/man');
                } else {
                    console.error(error);
                }
            });
        }
    }
}
</script>
